





















































































































































































































































import Vue, { PropType } from 'vue'
import log from '@/log'
import { DataTableHeader } from 'vuetify'

// my stuff
import { ApiInterface } from '@/api'
import { CreateFn, DeleteFn, ListFn, ReplaceFn } from '@/api/types'
import { evalValue } from '@/utils/eval-value'
import MessageBus from '@/message-bus'

// components
import RefItemEdit from '@/components/refdata/RefItemEdit.vue'
import { RefDataField } from '@/components/refdata/ref-data'
import JDateField from '@/components/controls/JDateField.vue'

// types
import { GenericRefItem, ID, UUID } from '@/types'
import { cloneDeep } from 'lodash'
type Model = GenericRefItem
import {
  Filter,
  NormalizedFilter,
  routeQueryToFilter,
  filterFn,
  normalizeFilterFn,
  getFilterLabels,
} from '@/utils/table-filter'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Item = any

export default Vue.extend({
  metaInfo() {
    return {
      title: this.label[1] as string,
    }
  },
  components: {
    RefItemEdit,
    JDateField,
  },
  props: {
    id: String,
    label: [String, Array] as PropType<string | [string, string]>,
    icon: String,
    storeState: String,
    sortBy: String,
    sortDesc: Boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fields: Array as PropType<RefDataField<any>[]>,
    to: Function,
    toField: String,

    mutateOne: String,
    mutateAll: String,
    mutateRemove: String,
    list: {
      type: Function as PropType<<T = unknown>(api: ApiInterface) => ListFn<T>>,
      required: false,
    },
    create: {
      type: Function as PropType<
        <T = unknown>(api: ApiInterface) => CreateFn<T>
      >,
      required: false,
    },
    replace: {
      type: Function as PropType<
        <T = unknown>(api: ApiInterface) => ReplaceFn<T>
      >,
      required: false,
    },
    delete: {
      type: Function as PropType<
        <R = unknown>(api: ApiInterface) => DeleteFn<R>
      >,
      required: false,
    },
  },
  data: () => ({
    localItems: [] as Model[],

    search: '',

    createDialog: false,
    replaceItem: undefined as GenericRefItem | unknown,

    deleteId: undefined as ID | undefined,
    isDeleting: false,

    isRefreshing: false,

    filterDialog: false,
  }),
  watch: {
    id: {
      immediate: true,
      handler() {
        if (!this.hasStore) {
          this.refreshItems()
        }
      },
    },
  },
  computed: {
    headers(): DataTableHeader[] {
      return [
        ...(this.hasReplace
          ? [
              {
                text: '',
                value: 'edit',
                align: 'center' as 'center',
                class: 'px-1',
                cellClass: 'px-1',
                width: 28 + 4 * 2,
              },
            ]
          : []),

        ...(this.hasDelete
          ? [
              {
                text: '',
                value: 'delete',
                align: 'center' as 'center',
                class: 'px-1',
                cellClass: 'px-1',
                width: 28 + 4 * 2,
              },
            ]
          : []),

        ...this.fields
          .filter(f => !f.hideInTable)
          .map(f => ({
            text: f.label,
            value: f.field,
            align: f.align || 'start',
          })),
      ]
    },
    hasStore(): boolean {
      return !!this.mutateAll && !!this.storeState
    },
    toFieldSlotName(): string {
      return `item.${this.toField || 'id'}`
    },
    inputFields(): RefDataField<Item>[] {
      return this.fields.filter(f => !!f.input)
    },
    labelOne(): string {
      return Array.isArray(this.label) ? this.label[0] : this.label
    },
    labelMany(): string {
      return Array.isArray(this.label) ? this.label[1] : this.label
    },
    items(): Model[] {
      return this.hasStore
        ? this.$store.state[this.storeState]
        : this.localItems
    },
    filter(): Filter {
      return routeQueryToFilter(this.$route.query, this.fields)
    },
    normalizedFilter(): NormalizedFilter<Item> {
      return normalizeFilterFn(this.filter, this.fields)
    },
    filterText(): (string | null)[] {
      return getFilterLabels(this.normalizedFilter, this.filteredItems)
    },
    filteredItems(): Model[] {
      return this.items.filter(filterFn(this.normalizedFilter))
    },
    hasId(): boolean {
      return this.fields.some(f => f.id === 'id')
    },
    hasList(): boolean {
      return !!this.list && (!this.hasStore || !!this.mutateAll)
    },
    hasCreate(): boolean {
      return !!this.create && (!this.hasStore || !!this.mutateOne)
    },
    hasReplace(): boolean {
      return !!this.replace && (!this.hasStore || !!this.mutateOne)
    },
    hasDelete(): boolean {
      return !!this.delete && (!this.hasStore || !!this.mutateRemove)
    },
    replaceDialog(): boolean {
      return !!this.replaceItem
    },
  },
  methods: {
    evalValue,
    getSlotFieldName(fieldName: string): string {
      return `item.${fieldName}`
    },
    setFilter(key: string, value: (string | number)[]): void {
      const query = cloneDeep(this.$route.query)
      delete query[key]
      if (value && value.length > 0) query[key] = value.toString()
      this.$router.replace({
        query,
      })
    },
    setFilterRange(
      key: string,
      index: 0 | 1,
      value: string | number | null
    ): void {
      const query = cloneDeep(this.$route.query)
      const orig = query[key] || []
      const prev = Array.isArray(orig)
        ? orig.map(z => z || '')
        : orig.split(',')
      delete query[key]

      const arr = [
        index === 0 ? (value === null ? '' : value).toString() : prev[0] || '',
        index === 1 ? (value === null ? '' : value).toString() : prev[1] || '',
      ]
      if (arr.some(v => v !== '')) query[key] = arr.join(',')
      this.$router.replace({
        query,
      })
    },
    getFilterIndexValue(value: string[], index: 0 | 1): string | null {
      return (value || [])[index]
    },
    doDelete(id: ID): void {
      if (!this.delete) throw new Error('delete function missing')

      this.isDeleting = true

      this.delete(this.$api)(id)
        .then(() => {
          if (this.mutateRemove) this.$store.commit(this.mutateRemove, id)
          MessageBus.success(`deleted #${id}`)
          this.onDelete(id)
          this.deleteId = undefined
        })
        .catch(err => {
          MessageBus.error(`error saving #${id}: ${err.message || err}`)
          log.error(err)
        })
        .finally(() => {
          this.isDeleting = false
        })
    },
    onCreate(item: Model): void {
      if (!this.hasStore) {
        // push to local list
        this.localItems.push(item)
      }
    },
    onReplace(item: Model): void {
      if (!this.hasStore) {
        // replace in local list
        const itemIndex = this.localItems.findIndex(z => z.id === item.id)

        if (itemIndex >= 0) {
          this.$set(this.localItems, itemIndex, item)
        }
      }
    },
    onDelete(id: ID | UUID): void {
      if (!this.hasStore) {
        // delete in local list
        const itemIndex = this.localItems.findIndex(z => z.id === id)

        if (itemIndex >= 0) {
          this.$delete(this.localItems, itemIndex)
        }
      }
    },
    refreshItems(): void {
      this.isRefreshing = true

      this.list<Model>(this.$api)()
        .then(items => {
          if (this.hasStore) {
            this.$store.commit(this.mutateAll, items)
          } else {
            this.localItems = items
          }

          MessageBus.success(`Updated ${this.labelMany}!`)
        })
        .catch(err => {
          MessageBus.error(
            `Refresh failed for ${this.labelMany} [${err.message}]`
          )
        })
        .finally(() => {
          this.isRefreshing = false
        })
    },
    scrollToTop() {
      this.$vuetify.goTo('.jad-data-table tbody tr', {
        offset: 50,
        container: '.v-data-table__wrapper',
      })
    },
  },
})
