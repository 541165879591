import { Dictionary } from 'vue-router/types/router'
import { evalValue } from '@/utils/eval-value'

export type Filter = {
  [key: string]: FilterValue
}

type FilterValue = (string | number)[]

export type FilterableField<T> = {
  id: string
  label: string
  filter: FilterableFieldFilter
  value: (item: T) => number | string | boolean | null
  idGetter?: (item: T) => number | string
  castId?: (value: string) => number | string
}

type FilterableFieldFilter =
  | 'none'
  | 'list'
  | 'number'
  | 'search'
  | 'date-range'

export interface NormalizedFilter<T> {
  [key: string]: {
    type: 'number' | 'list' | 'search' | 'date-range'
    field: FilterableField<T>
    value: any // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}

export const routeQueryToFilter = <T>(
  routeQuery: Dictionary<string | (string | null)[]>,
  fields: FilterableField<T>[],
  arraySplitDelimiter = '|'
) => {
  const f = Object.entries(routeQuery || {})
    .filter(([key]) => fields.some(f => f.id === key))
    .reduce((prev, [key, value]) => {
      const field = fields.find(f => f.id === key)
      if (!field) return prev
      const cast = field.castId ? field.castId : (v: string | number) => v
      const values = Array.isArray(value)
        ? (value as any[]) // eslint-disable-line @typescript-eslint/no-explicit-any
        : (value.toString().split(arraySplitDelimiter) as any[]) // eslint-disable-line @typescript-eslint/no-explicit-any
      return {
        ...prev,
        [key]:
          field.filter === 'number'
            ? values.map(v => (v === '' ? '' : Number(v)))
            : values.map(cast),
      }
    }, {} as Filter)

  return f
}

export const normalizeFilterFn = <T>(
  filter: Filter,
  fields: FilterableField<T>[]
) => {
  return Object.entries(filter).reduce((prev, [key, value]) => {
    const field = fields.find(f => f.id === key)
    if (!field || value === null || field.filter === 'none') return prev
    if (field.filter === 'list' && value.length <= 0) return prev
    if (field.filter === 'search' && value.toString().trim() === '') return prev
    if (
      ['date-range', 'number'].includes(field.filter) &&
      (value.length < 2 || (value[0] === null && value[1] === null))
    )
      return prev
    return {
      ...prev,
      [key]: {
        type: field.filter,
        field,
        value,
      },
    }
  }, {} as NormalizedFilter<T>)
}

export const filterFn = <T>(normalizedFilter: NormalizedFilter<T>) => {
  return (item: T): boolean =>
    Object.entries(normalizedFilter).every(([key, obj]) => {
      const field = obj.field

      let v0, v1

      const getter = (item: T) =>
        obj.field.idGetter ? obj.field.idGetter(item) : evalValue(item, key)

      switch (field.filter) {
        case 'list':
          return (obj.value as Array<string | number>).includes(getter(item))
        case 'search':
          v0 = getter(item)
          if (!v0) return false
          return v0.toLowerCase().includes(obj.value.toString().toLowerCase())
        case 'number':
        case 'date-range':
          ;[v0, v1] = obj.value as [
            string | number | null | undefined,
            string | number | null | undefined
          ]
          if (
            (v0 === null || v0 === undefined) &&
            (v1 === null || v1 === undefined)
          ) {
            return true
          } else if (v0 === null || v0 === undefined || v0 === '') {
            return getter(item) <= (v1 as string | number)
          } else if (v1 === null || v1 === undefined || v1 === '') {
            return getter(item) >= (v0 as string | number)
          } else {
            return (
              getter(item) >= (v0 as string | number) &&
              getter(item) <= (v1 as string | number)
            )
          }
        default:
          return true
      }
    })
}

export const getFilterLabels = <T>(
  normalizedFilter: NormalizedFilter<T>,
  items: T[]
) => {
  return Object.entries(normalizedFilter).map(([, value]) => {
    switch (value.type) {
      // TODO: fix value getter (get from vuex store instead)
      case 'list':
        return `${value.field.label} = ${value.value
          .map((v: string | number) => {
            const idGetter = value.field.idGetter
              ? value.field.idGetter
              : (theValue: T) => evalValue(theValue, value.field.id)
            const obj = items.find(z => idGetter(z) === v)

            if (obj) {
              const item = items.find(z => idGetter(z) === v)
              return item ? value.field.value(item) : '??? unknown ???'
            } else {
              return v
            }
          })
          .join(' or ')}`
      case 'search':
        return `${value.field.label} = ${value.value}`
      case 'date-range':
      case 'number':
        return value.value[0] === null ||
          value.value[0] === undefined ||
          value.value[0] === ''
          ? `${value.field.label} <= ${value.value[1]}`
          : value.value[1] === null ||
            value.value[1] === undefined ||
            value.value[1] === ''
          ? `${value.field.label} >= ${value.value[0]}`
          : `${value.field.label} between ${value.value[0]} and ${value.value[1]}`
      default:
        return `${value.field.label} = ${value.value}`
    }
  })
}
